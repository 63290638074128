import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";

function UpcomingEvents() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.upcoming_events.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.upcoming_events.title")} titleKey="pages.upcoming_events.title" isWidthSmall={false}>
        <h2 data-key="pages.upcoming_events.subtitle_01">{t("pages.upcoming_events.subtitle_01")}</h2>
        <p data-key="pages.upcoming_events.text_01">{t("pages.upcoming_events.text_01")}</p>
        <iframe
          title="Schedule of Educational Webinars"
          src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FZurich&hl=en&showPrint=0&src=YzBjMzQxYTNiNTRiMzlmYzZhZGQwZGNkMDIwMmMxYjNkZWEyMGM1OTk0MGMxNGVkM2YzMmVmZGU1ZWVmYTEyYUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5"
          style={{ border: 0, overflow: "hidden" }}
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>

        <h2 data-key="pages.upcoming_events.subtitle_02">{t("pages.upcoming_events.subtitle_02")}</h2>
        <p data-key="pages.upcoming_events.text_02">{t("pages.upcoming_events.text_02")}</p>
        <iframe
          title="Student Events"
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FZurich&hl=en&showPrint=0&src=Y181NzM5NDNmMzkwNTVlNTE5MTdlN2VjYmNmOTlhZmRjZTA1ZmUxY2Q1OTRhMmNmODliYjdhYzlmN2ViNTI4MzQ1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F6BF26"
          style={{ border: 0, overflow: "hidden" }}
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>

        <h2 data-key="pages.upcoming_events.subtitle_03">{t("pages.upcoming_events.subtitle_03")}</h2>
        <p data-key="pages.upcoming_events.text_03">{t("pages.upcoming_events.text_03")}</p>
        <iframe
          title="Activities of the SIIL Teaching Center"
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FZurich&hl=en&showPrint=0&src=Y182MGQ3ZGYxMmQ0ZGQyYzg3NjMwOWRlZWE0YzI4ZmRjNWVjZWQ2ZTU5N2YyOGRkMjcxY2QyNzBjNzc5OTg0NDM4QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23A79B8E"
          style={{ border: 0, overflow: "hidden" }}
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>

        <h2 data-key="pages.upcoming_events.subtitle_04">{t("pages.upcoming_events.subtitle_04")}</h2>
        <p data-key="pages.upcoming_events.text_04">{t("pages.upcoming_events.text_04")}</p>
        <iframe
          title="Quality Assurance Events for Offices"
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FZurich&hl=en&showPrint=0&src=Y19lM2M2YjVhYTQ0YmE4MDBkMzMzOWEyY2NhZDJlZDJhYzk1OWRhNGY3YWZhYWZjMTg1NzJlMmJmYTBhZTAxNWRiQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237986CB"
          style={{ border: 0, overflow: "hidden" }}
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </Page>
    </MainLayout>
  );
}

export default UpcomingEvents;
