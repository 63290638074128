import MainLayout from "../layout";
import { LinkDecor } from "@vshp/ui-components";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function DigitalAccessibility() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.digital_accessibility.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.digital_accessibility.title")} titleKey="pages.digital_accessibility.title" isWidthSmall={true}>
        <div data-key="pages.digital_accessibility.text" dangerouslySetInnerHTML={{ __html: t("pages.digital_accessibility.text") }}></div>
        {locale !== "ru" ? (
          <LinkDecor link="/docs/9538/Statement_of_Special_Educational_Needs.pdf">Statement of Special Educational Needs</LinkDecor>
        ) : (
          <LinkDecor link="/docs/9539/Zayavlenie_o_nalichii_osobyh_obrazovatelnyh_potrebnosteĭ.pdf">Заявление о наличии особых образовательных потребностей</LinkDecor>
        )}
      </Page>
    </MainLayout>
  );
}

export default DigitalAccessibility;
