export const MENU_OPEN_CLASS = "nav-open";
export const LANGUAGES_OPEN_CLASS = "languages-open";
export const THEME_DARK_CLASS = "theme_dark";

export const RESPONSE_ERRORS = "RESPONSE_ERRORS";
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";

export const CURRENT_LANGUAGE = "CURRENT_LANGUAGE";
export const DEFAULT_LANGUAGE = "en";

export const SOURCE = "siil";

// accessibility

export const ACCESSIBILITY = "ACCESSIBILITY";
export const ACCESSIBILITY_ACTIVE_SIZE = "ACCESSIBILITY_ACTIVE_SIZE";
export const ACCESSIBILITY_ACTIVE_COLOR = "ACCESSIBILITY_ACTIVE_COLOR";

export const ACCESSIBILITY_CONTENT = ["A", "A", "A"];
export const ACCESSIBILITY_SIZE_CLASS = "accessibility-size";
export const ACCESSIBILITY_COLOR_CLASS = "accessibility-color";
export const ACCESSIBILITY_OPEN_CLASS = "accessibility-open";

/* Redux general constants */
export const IP_ADDRESS = "IP_ADDRESS";
export const COURSE_DEGREE_ID = 9;

// Slider

export const MAX_INDICATORS_STD = 2;
export const MAX_INDICATORS_COUNT = 5;

export const NAVIGATION = [
  {
    name: "ui.menu_item_about",
    submenu: [
      {
        name: "ui.menu_item_institute",
        url: "/institute",
      },
      {
        name: "ui.menu_item_reviews",
        url: "/reviews",
      },

      // {
      //   name: "ui.menu_item_teaching_staff",
      //   url: "/teaching-staff",
      //   submenu: [
      //     {
      //       name: "ui.menu_item_program_directors",
      //       url: "/teaching-staff/program-directors",
      //     },
      //   ],
      // },

      {
        name: "ui.menu_item_community",
        url: "/community",
      },
      {
        name: "ui.menu_item_blog",
        url: "/blog",
      },
      {
        name: "ui.menu_item_upcoming_events",
        url: "/events",
      },
      {
        name: "ui.menu_item_contacts",
        url: "/contacts",
        submenu: [
          {
            name: "ui.menu_item_impressum",
            url: "/impressum",
          },
        ],
      },
    ],
  },
  // programs
  {
    name: "ui.menu_item_programs",
    url: "/programs",
    submenu: [
      {
        name: "ui.menu_item_it_faculty",
        url: "/it",
      },
      {
        name: "ui.menu_item_management_faculty",
        url: "/management-faculty",
      },
      {
        name: "ui.menu_item_teaching_staff",
        url: "/teaching-staff",
      },
      {
        name: "ui.menu_item_program_directors",
        url: "/program-directors",
      },
      {
        name: "ui.menu_item_teaching_center",
        url: "/teaching-center",
      },
    ],
  },

  // student-services

  {
    name: "ui.menu_item_addmissions",
    url: "/study-siil",
    submenu: [
      {
        name: "ui.menu_item_study_siil",
        url: "/study-siil",
      },
      {
        name: "ui.menu_item_digital_accessibility",
        url: "/digital-accessibility",
      },
      {
        name: "ui.menu_item_student_services",
        url: "/student-services",

        submenu: [
          {
            name: "ui.menu_item_academic",
            url: "/student-services/academic-assistance-team",
          },
          {
            name: "ui.menu_item_career_advice",
            url: "/student-services/career-advice-team",
          },
          {
            name: "ui.menu_item_learning_support",
            url: "/student-services/learning-support-team",
          },
          {
            name: "ui.menu_item_addmission",
            url: "/student-services/admissions-and-records-team",
          },
        ],
      },

      {
        name: "ui.menu_item_e_learning_platform",
        url: "/e-learning-platform",
      },
      {
        name: "ui.menu_item_virtual_mobility",
        url: "/virtual-mobility",
      },
      {
        name: "ui.menu_item_e_library",
        url: "/e-library",
      },

      {
        name: "ui.menu_item_alumni_network",
        url: "/alumni",
      },
      {
        name: "ui.menu_item_diploma",
        url: "/documents",
      },

      {
        name: "ui.menu_item_reviews",
        url: "/reviews",
      },
      {
        name: "ui.menu_item_faq",
        url: "/faq",
      },
    ],
  },

  // quality-assurance-office

  {
    name: "ui.menu_item_quality_assurance_office",
    url: "/quality-assurance-office",

    submenu: [
      // {
      //   name: "ui.menu_item_it_security",
      //   url: "/it-security",
      // },
    ],
  },

  // documents_and_downloads

  {
    name: "ui.menu_item_documents_and_downloads",

    submenu: [
      {
        name: "ui.menu_item_strategic_plans",
        url: "/strategic-plans",
      },
      {
        name: "ui.menu_item_annual_reports",
        url: "/reports",
      },
      {
        name: "ui.menu_item_statutory_documents",
        url: "/statutory-documents",
      },

      {
        name: "ui.menu_item_general_information",
        url: "/general-information",
      },

      {
        name: "ui.menu_item_awards_and_accreditations",
        url: "/awards-and-accreditations",
      },
      {
        name: "ui.menu_item_presskit",
        url: "/presskit",
      },
      {
        name: "ui.menu_item_policies",
        url: "/policies",
      },
      {
        name: "ui.menu_item_study_plans",
        url: "/study-plans",
      },
      {
        name: "ui.menu_item_important_information_for_students",
        url: "/important-information-for-students",
      },
    ],
  },
];

export const NAVIGATION_FOOTER = [
  // {
  //   name: "ui.menu_item_about",
  //   url: "/about-us",
  // },
  {
    name: "ui.menu_item_faculties",
    url: "/faculties",
  },
  { name: "ui.menu_item_programs", url: "/programs" },
  { name: "ui.menu_item_addmissions", url: "/student-services" },
  {
    name: "ui.menu_item_community",
    url: "/alumni",
  },
  {
    name: "ui.menu_item_blog",
    url: "/blog",
  },
  {
    name: "ui.menu_item_diploma",
    url: "/documents",
  },
  {
    name: "ui.menu_item_partners",
    url: "/partners",
  },
  // {
  //   name: "ui.menu_item_agreement",
  //   url: "/agreement",
  // },
  // {
  //   name: "ui.menu_item_privacy",
  //   url: "/privacy",
  // },
  // {
  //   name: "ui.menu_item_security_policy",
  //   url: "/security-policy",
  // },
  // {
  //   name: "ui.menu_item_data_protection_policy",
  //   url: "/data-protection-policy",
  // },
  // {
  //   name: "ui.menu_item_cookies_policy",
  //   url: "/cookies-policy",
  // },
  {
    name: "ui.menu_item_impressum",
    url: "/impressum",
  },
  {
    name: "ui.menu_item_disclaimer",
    url: "/disclaimer",
  },
  {
    name: "ui.menu_item_faq",
    url: "/faq",
  },
  {
    name: "ui.menu_item_reviews",
    url: "/reviews",
  },
  {
    name: "ui.menu_item_presskit",
    url: "/presskit",
  },
  {
    name: "ui.menu_item_upcoming_events",
    url: "/events",
  },
  {
    name: "ui.menu_item_digital_accessibility",
    url: "/digital-accessibility",
  },
  { name: "ui.menu_item_contacts", url: "/contacts" },
];
