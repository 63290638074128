import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { LinkDecor } from "@vshp/ui-components";
import i18n from "i18next";

function AnnualReports() {
  const { t } = useTranslation();
  let locale = i18n.language;
  let title = {
    caption: t("pages.annual_reports.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.annual_reports.title")} titleKey="pages.annual_reports.title" isWidthSmall={true}>
        <LinkDecor link="/docs/9018/Annual_report_2023.pdf">Annual report 2023</LinkDecor>
        <LinkDecor link="/docs/9537/Annual_report_2022.pdf">Annual report 2022</LinkDecor>
        <LinkDecor link="/docs/9524/Otchet_po_oprosu_studentov.pdf">{locale !== "ru" ? "Report on the Student Survey" : "Отчет по опросу студентов"}</LinkDecor>
        <LinkDecor link="/docs/9523/Otchet_po_anketirovaniyu_PPS_SIIL.pdf">{locale !== "ru" ? "Report on the Survey of SIIL Faculty" : "Отчет по анкетированию ППС SIIL"}</LinkDecor>
        <LinkDecor link="/docs/9525/Otchet_po_anketirovaniyu_studentov.pdf">{locale !== "ru" ? "Report on the Student Questionnaire" : "Отчет по анкетированию студентов"}</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default AnnualReports;
