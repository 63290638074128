import MainLayout from "../layout";
import Page from "../page/Page";
import { LinkDecor } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function VirtualMobility() {
  const { t } = useTranslation();

  let title = {
    caption: t("pages.virtual_mobility.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.virtual_mobility.title")} titleKey="pages.virtual_mobility.title" isWidthSmall={true}>
        <div data-key="pages.virtual_mobility.text" dangerouslySetInnerHTML={{ __html: t("pages.virtual_mobility.text") }}></div>
        <LinkDecor link="/docs/9534/Virtual_Mobility_Regulations.pdf">Virtual Mobility Regulations</LinkDecor>
      </Page>
    </MainLayout>
  );
}

export default VirtualMobility;
