import MainLayout from "../layout";
import Page from "../page/Page";
import { useTranslation } from "react-i18next";
import { LinkDecor } from "@vshp/ui-components";
import i18n from "i18next";

function Policies() {
  const { t } = useTranslation();
  let locale = i18n.language;

  let title = {
    caption: t("pages.policies.title"),
  };

  return (
    <MainLayout title={title}>
      <Page title={t("pages.policies.title")} titleKey="pages.policies.title" isWidthSmall={true}>
        {locale !== "ru" ? (
          <>
            <LinkDecor link="/docs/902e/Academic_freedom_statement.pdf">Academic Freedom Statement</LinkDecor>
            <LinkDecor link="/docs/9023/Academic_Integrity_and_Academic_misconduct_terms_and_procedures.pdf">Academic Integrity and Academic Misconduct Terms and Procedures</LinkDecor>
            <LinkDecor link="/docs/9528/Advisory_Board_Policy.pdf">Advisory Board Policy</LinkDecor>
            <LinkDecor link="/docs/9529/Advisory_Board_Selection_Procedures.pdf">Advisory Board Selection Procedures</LinkDecor>
            <LinkDecor link="/docs/952a/Antipliagiarism_Guidelines_and_Citation_Rules.pdf">Antipliagiarism Guidelines and Citation Rules</LinkDecor>
            <LinkDecor link="/docs/901e/Appendix_A_to_RABA.pdf">Appendix A To Rules Of Admission To Bachelor Programmes (for Swiss diploma)</LinkDecor>
            <LinkDecor link="/docs/901f/Appendix_B_to_RABA.pdf">Appendix B To Rules Of Admission To Bachelor Programmes (for foreign diploma)</LinkDecor>
            <LinkDecor link="/docs/9032/Cloud_policy_SIIL.pdf">Cloud Computing Policy and Guidelines</LinkDecor>
            <LinkDecor link="/docs/9033/Cookie_policy.pdf">Cookie Policy</LinkDecor>
            <LinkDecor link="/docs/9034/Data_Breach_procedural_Guidelines.pdf">Data Breach Notification – Procedural Guidelines</LinkDecor>
            <LinkDecor link="/docs/9035/Data_Protection_Policy_.pdf">Data Protection Policy</LinkDecor>
            <LinkDecor link="/docs/9535/Digital_Innovations_Roadmap.pdf">Digital Innovations Roadmap</LinkDecor>
            <LinkDecor link="/docs/9536/Digital_Innovation_Strategy.pdf">Digital Innovation Strategy</LinkDecor>
            <LinkDecor link="/docs/9036/Employment_Equality_Policy.pdf">Employment Equality Policy</LinkDecor>
            <LinkDecor link="/docs/9037/Equality_policy.pdf">Equality Policy</LinkDecor>
            <LinkDecor link="/docs/9038/External_communication_policy.pdf">External Communications, Media Relations, and Social Media Policy</LinkDecor>
            <LinkDecor link="/docs/9039/General_Procedures_for_Quality_Reviews_.pdf">General Procedures for Quality Reviews</LinkDecor>
            <LinkDecor link="/docs/901b/General_Regulations_SIIL.pdf">General Regulations SIIL</LinkDecor>
            <LinkDecor link="/docs/952b/Guidelines_for_Writing_Courseworks__BA_and_MA_Theses.pdf">Guidelines for Writing Courseworks, BA and MA Theses</LinkDecor>
            <LinkDecor link="/docs/903c/Guidelines_for_A_New_Course_Proposal.pdf">Guidelines for a New Course Proposal</LinkDecor>
            <LinkDecor link="/docs/903d/HR_Recruitment_policy.pdf">Human Resources Recruitment Policy</LinkDecor>
            <LinkDecor link="/docs/903e/IT_Security_policy.pdf">IT Security Policy</LinkDecor>
            <LinkDecor link="/docs/903f/Learning_outcomes_assessement_policy.pdf">Learning Outcomes Assessment Policy</LinkDecor>
            <LinkDecor link="/docs/9041/New_Programme_design_and_approval_policy.pdf">New Programme Design and Approval Policy</LinkDecor>
            <LinkDecor link="/docs/9022/Plagiarism_Policy.pdf">Plagiarism Policy</LinkDecor>
            <LinkDecor link="/docs/9042/Policy_and_Procedure_on_Engagement_of_Externally_Contracted_Service_Providers.pdf">
              Policy and Procedure on Engagement of Externally Contracted Service Providers
            </LinkDecor>
            <LinkDecor link="/docs/9043/Policy_on_social_networking_and_social_media.pdf">Policy on Social Networking and Social Media</LinkDecor>
            <LinkDecor link="/docs/9053/Privacy_Policy_2023.pdf">Privacy Policy</LinkDecor>
            <LinkDecor link="/docs/9044/Quality_Assurance_Policy.pdf">Quality Assurance Policy</LinkDecor>
            <LinkDecor link="/docs/952c/Reasonable_Accomodation_Policy_for_Students_with_Special_Needs.pdf">Reasonable Accommodation Policy for Students with Special Needs</LinkDecor>
            <LinkDecor link="/docs/9046/Records_Management_Policy.pdf">Records Management Policy</LinkDecor>
            <LinkDecor link="/docs/8fa9/Rules_of_Admission_Bachelor_Programmes.pdf">Rules of Admission for Bachelor Programmes</LinkDecor>
            <LinkDecor link="/docs/8faa/Rules_of_Admission_Master_Programmes.pdf">Rules of Admission for Master Programmes</LinkDecor>
            <LinkDecor link="/docs/9530/SIIL_LMS_based_monitoring_principles.pdf">SIIL LMS based monitoring principles</LinkDecor>
            <LinkDecor link="/docs/9531/SP_Regular_Review_Procedures_final_version.pdf">SP Regular Review Procedures</LinkDecor>
            <LinkDecor link="/docs/9047/Staff_Developement_Procedures.pdf">Staff Development Procedures</LinkDecor>
            <LinkDecor link="/docs/9020/Student_Complaints_Procedures.pdf">Student Complaints Procedure</LinkDecor>
            <LinkDecor link="/docs/9021/Student_complaints_template.pdf">Student Complaints Procedure – Complaint Form</LinkDecor>
            <LinkDecor link="/docs/9532/Student_Progression_Assessment_Policy.pdf">Student Progression Assessment Policy</LinkDecor>
            <LinkDecor link="/docs/9533/Study_Programme_Monitoring_and_Review_Procedures_final_version.pdf">Study Programme Monitoring and Review Procedures</LinkDecor>
            <LinkDecor link="/docs/9048/Study_programme_review_procedures.pdf">Study Programme Review Procedures</LinkDecor>
            <LinkDecor link="/docs/8fa7/Study_Regulations_for_Bachelor_Programmes_PDF.pdf">Study Regulations for Bachelor Programmes</LinkDecor>
            <LinkDecor link="/docs/8fa8/Study_Regulations_for_Master_Programmes.pdf">Study Regulations for Master Programmes</LinkDecor>
            <LinkDecor link="/docs/9024/Technical_requirements_for_online_access_from_online_versions_in_viewing_mode.pdf">
              Technical requirements for online access from online versions in viewing mode
            </LinkDecor>
            <LinkDecor link="/docs/8fac/Tuition_fees_regulations_Master.pdf">Tuition fees regulations</LinkDecor>
            <LinkDecor link="/docs/9040/Mentoring_and_tutoring_guidelines.pdf">Tutoring and Mentoring Guidelines</LinkDecor>
            <LinkDecor link="/docs/9534/Virtual_Mobility_Regulations.pdf">Virtual Mobility Regulations</LinkDecor>
          </>
        ) : (
          <>
            <LinkDecor link="/docs/9098/Zayavlenie_ob_akademicheskoy_svobode.pdf">Заявление об академической свободе</LinkDecor>
            <LinkDecor link="/docs/9096/Akademicheskaya_chestnost_i_akademicheskie_narusheniya-_terminy_i_protsedury__1_.pdf">
              Академическая честность и академические нарушения- термины и процедуры
            </LinkDecor>
            <LinkDecor link="/docs/90a2/Prilozhenie_A_k_Pravilam_zachisleniya_na_programmy_bakalavriata_.pdf">Приложение А к Правилам зачисления на программы бакалавриата</LinkDecor>
            <LinkDecor link="/docs/90a3/Prilozhenie_B_k_Pravilam_zachisleniya_na_programmy_bakalavriata_.pdf">Приложение Б к Правилам зачисления на программы бакалавриата</LinkDecor>
            <LinkDecor link="/docs/9032/Cloud_policy_SIIL.pdf">Политика облачной безопасности</LinkDecor>
            <LinkDecor link="/docs/90b2/Politika_po_faylam_cookie.pdf">Политика по файлам cookie</LinkDecor>
            <LinkDecor link="/docs/90b3/Rukovodstvo_po_protsedure_pr_vzlome_dannyh.pdf">Руководство по процедуре при взломе данных</LinkDecor>
            <LinkDecor link="/docs/90b4/Politika_po_zaschite_dannyh.pdf">Политика по защите данных</LinkDecor>
            <LinkDecor link="/docs/90a0/Politika_obespecheniya_ravnyh_vozmozhnostey_pri_trudoustroystve.pdf">Политика обеспечения равных возможностей при трудоустройстве</LinkDecor>
            <LinkDecor link="/docs/90a1/Politika_obespecheniya_ravnyh_vozmozhnostey.pdf">Политика обеспечения равных возможностей</LinkDecor>
            <LinkDecor link="/docs/909e/Politika_v_otnoshenii_sotsialnyh_setey_i_sotsialnyh_media.pdf">Политика в отношении социальных сетей и социальных медиа</LinkDecor>
            <LinkDecor link="/docs/909a/Obschie_protsedury_dlya_audita_kachestva.pdf">Общие процедуры для аудита качества</LinkDecor>
            <LinkDecor link="/docs/9099/OBSCHIE_POLOZHENIYA_2023.2028.pdf">Общие положения</LinkDecor>
            <LinkDecor link="/docs/90b6/Rukovodstvo_po_sozdaniyu_novogo_kursa.pdf">Руководство по созданию нового курса</LinkDecor>
            <LinkDecor link="/docs/909c/Politika_v_oblasti_podbora_personala.pdf">Политика в области подбора персонала</LinkDecor>
            <LinkDecor link="/docs/90b7/Politika_po_bezopasnosti_IT.pdf">Политика по безопасности ИТ</LinkDecor>
            <LinkDecor link="/docs/90b8/Politika_po_otsenke_rezultatov_obucheniya.pdf">Политика по оценке результатов обучения</LinkDecor>
            <LinkDecor link="/docs/90b9/Politika_po_razrabotke_i_utverzhdeniyu_novyh_programm.pdf">Политика по разработке и утверждению новых программ</LinkDecor>
            <LinkDecor link="/docs/909d/Politika_v_otnoshenii_plagiata.pdf">Политика в отношении плагиата</LinkDecor>
            <LinkDecor link="/docs/90ba/Politika_konfidentsialnosti.pdf">Политика конфиденциальности</LinkDecor>
            <LinkDecor link="/docs/90bb/Politika__i_protsedura_po_privlecheniyu_vneshnih_postavschikov_uslug_po_dogovoru.pdf">
              Политика и процедура по привлечению внешних поставщиков услуг по договору
            </LinkDecor>
            <LinkDecor link="/docs/909e/Politika_v_otnoshenii_sotsialnyh_setey_i_sotsialnyh_media.pdf">Политика в отношении социальных сетей и социальных медиа</LinkDecor>
            <LinkDecor link="/docs/909f/Politika_obespecheniya_kachestva.pdf">Политика обеспечения качества</LinkDecor>
            <LinkDecor link="/docs/90bc/Politika_po_upravleniyu_dokumentooborotom.pdf">Политика по управлению документооборотом</LinkDecor>
            <LinkDecor link="/docs/90a4/Pravila_zachisleniya_na_programmy_bakalavriata.pdf">Правила зачисления на программы бакалавриата</LinkDecor>
            <LinkDecor link="/docs/90a5/Pravila_zachisleniya_na_programmy_magistratury.pdf">Правила зачисления на программы магистратуры</LinkDecor>
            <LinkDecor link="/docs/90bd/Protsedury_razvitiya_personala.pdf">Процедуры развития персонала</LinkDecor>
            <LinkDecor link="/docs/90b0/Protsedura_podachi_zhalob_studentami__1_.pdf">Процедура подачи жалоб студентами</LinkDecor>
            <LinkDecor link="/docs/90a6/Protsedura_podachi_zhalob_studentami_-_forma_zhaloby.pdf">Процедура подачи жалоб студентами - форма жалобы</LinkDecor>
            <LinkDecor link="/docs/90be/Protsedury_audita_obrazovatelnyh_programm.pdf">Процедуры аудита образовательных программ</LinkDecor>
            <LinkDecor link="/docs/90a7/Pravila_obucheniya_na_programmah_bakalavriata.pdf">Правила обучения на программах бакалавриата</LinkDecor>
            <LinkDecor link="/docs/90a8/Pravila_obucheniya_na_programmah_magistratury.pdf">Правила обучения на программах магистратуры</LinkDecor>
            <LinkDecor link="/docs/90b1/Tehnicheskie_trebovaniya_k_kompyuteru_dlya_onlayn_dostupa_iz_setevyh_.pdf">
              Технические требования к онлайн-доступу из онлайн-версий в режиме просмотра
            </LinkDecor>
            <LinkDecor link="/docs/90bf/Rekommendatsii_po_tyutoringu_i_nastavnichestvu.pdf">Рекомендации по тьюторингу и наставничеству</LinkDecor>
            <LinkDecor link="/docs/9557/Rukovodstvo_dlya_studentov_po_vypolneniyu_rabot.pdf">Руководство для студентов по выполнению курсовых и выпускных работ</LinkDecor>
            <LinkDecor link="/docs/9528/Advisory_Board_Policy.pdf">Advisory Board Policy</LinkDecor>
            <LinkDecor link="/docs/9529/Advisory_Board_Selection_Procedures.pdf">Advisory Board Selection Procedures</LinkDecor>
            <LinkDecor link="/docs/952a/Antipliagiarism_Guidelines_and_Citation_Rules.pdf">Antipliagiarism Guidelines and Citation Rules</LinkDecor>
            <LinkDecor link="/docs/9535/Digital_Innovations_Roadmap.pdf">Digital Innovations Roadmap</LinkDecor>
            <LinkDecor link="/docs/9536/Digital_Innovation_Strategy.pdf">Digital Innovation Strategy</LinkDecor>
            <LinkDecor link="/docs/952c/Reasonable_Accomodation_Policy_for_Students_with_Special_Needs.pdf">Reasonable Accomodation Policy for Students with Special Needs</LinkDecor>
            <LinkDecor link="/docs/9530/SIIL_LMS_based_monitoring_principles.pdf">SIIL LMS based monitoring principles</LinkDecor>
            <LinkDecor link="/docs/9531/SP_Regular_Review_Procedures_final_version.pdf">SP Regular Review Procedures</LinkDecor>
            <LinkDecor link="/docs/9532/Student_Progression_Assessment_Policy.pdf">Student Progression Assessment Policy</LinkDecor>
            <LinkDecor link="/docs/9533/Study_Programme_Monitoring_and_Review_Procedures_final_version.pdf">Study Programme Monitoring and Review Procedures</LinkDecor>
            <LinkDecor link="/docs/9534/Virtual_Mobility_Regulations.pdf">Virtual Mobility Regulations</LinkDecor>
          </>
        )}
      </Page>
    </MainLayout>
  );
}

export default Policies;
